import styled from 'styled-components'
import { Box, Flex, Image } from '@dex/uikit'
import Footer from 'components/Menu/Footer'
import { PageMeta } from 'components/Layout/Page'

const PageBackground = styled.div`
  background: #25274d;
`
const StyledPage = styled.div<{ $removePadding: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${({ $removePadding }) => ($removePadding ? '0' : '16px')};
  padding-bottom: 0;
  min-height: 100vh;
  padding-top: 160px!important;

  ${({ theme }) => theme.mediaQueries.xs} {
    background-size: auto;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: ${({ $removePadding }) => ($removePadding ? '0' : '24px')};
    padding-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: ${({ $removePadding }) => ($removePadding ? '0' : '32px')};
    padding-bottom: 0;

    background-repeat: no-repeat;
    background-position: right -15% top 110%;
    background-size: 35%!important;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    background-repeat: no-repeat;
    background-position: right -15% top 120%;
    background-size: 35%!important;
  }
`

const Page: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { removePadding?: boolean; hideFooterOnDesktop?: boolean }
> = ({ children, removePadding = false, hideFooterOnDesktop = false, ...props }) => {
  return (
    <>
      <PageMeta />
      <PageBackground>
        <StyledPage $removePadding={removePadding} {...props}>
          {children}
          <Flex flexGrow={1} />
        </StyledPage>
      </PageBackground>
    </>
  )
}

export default Page
